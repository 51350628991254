var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"page-course"},[_vm._m(0),_c('div',{staticClass:"course"},[_vm._m(1),_c('div',{staticClass:"step blue"},[_c('p',{staticClass:"phase"},[_vm._v("PHASE ONE")]),_c('p',{staticClass:"duration"},[_vm._v("2013 - 2014")]),_c('p',{staticClass:"sub-title"},[_vm._v(" From representative model to acquisition and development model ")]),_c('p',{staticClass:"text"},[_vm._v("2013 JWP launched at Athens, Greece")]),_c('p',{staticClass:"text"},[_vm._v("2013.3 Portugal branch established in Lisbon")]),_c('p',{staticClass:"text"},[_vm._v("2013 Cyprus Atlantis Gardens Project")]),_c('div',{directives:[{name:"lazy",rawName:"v-lazy:background-image",value:(
          '//static.hoolihome.com/global-jwp/assets/m/about/course-1.jpg'
        ),expression:"\n          '//static.hoolihome.com/global-jwp/assets/m/about/course-1.jpg'\n        ",arg:"background-image"}],staticClass:"milestone"},[_c('p',{staticClass:"milestone-title"},[_vm._v("MILESTONE 01")]),_c('p',{staticClass:"milestone-desc"},[_vm._v(" 2013 Cyprus Atlantis Gardens Project, the Beginning of Brand Recognition ")])]),_c('p',{staticClass:"text"},[_vm._v("2014 Portugal Belas Golf Residence Project")])]),_c('div',{staticClass:"step red"},[_c('p',{staticClass:"phase"},[_vm._v("PHASE TWO")]),_c('p',{staticClass:"duration"},[_vm._v("2015 - 2016")]),_c('p',{staticClass:"sub-title"},[_vm._v(" From signal development model to diversified development model ")]),_c('p',{staticClass:"text"},[_vm._v(" 2015 Portugal 8 Building，the landmark residential project ")]),_c('p',{staticClass:"text"},[_vm._v("2015 Radisson Hotel signed a contract successfully")]),_c('div',{directives:[{name:"lazy",rawName:"v-lazy:background-image",value:(
          '//static.hoolihome.com/global-jwp/assets/m/about/course-2.jpg'
        ),expression:"\n          '//static.hoolihome.com/global-jwp/assets/m/about/course-2.jpg'\n        ",arg:"background-image"}],staticClass:"milestone"},[_c('p',{staticClass:"milestone-title"},[_vm._v("MILESTONE 02")]),_c('p',{staticClass:"milestone-desc"},[_vm._v(" 2015 Radisson Blu signed successful, forming the multi-industry landscape of residential and hospitality ")])]),_c('p',{staticClass:"text"},[_vm._v("2015.8 Thailand branch established in Bangkok")]),_c('p',{staticClass:"text"},[_vm._v("2015.12 Ireland branch established")]),_c('p',{staticClass:"text"},[_vm._v("2016 Portugal Mastro Apartment project")]),_c('p',{staticClass:"text"},[_vm._v("2016 Ireland Kilcoole Nursing Home project")])]),_c('div',{staticClass:"step blue"},[_c('p',{staticClass:"phase"},[_vm._v("PHASE THREE")]),_c('p',{staticClass:"duration"},[_vm._v("2017 to date")]),_c('p',{staticClass:"sub-title"},[_vm._v(" Extending the 4 development sectors with large scale and high profile, empowered JWP business model from real estate developing, exclusive representative, to property services sufficiently ")]),_c('p',{staticClass:"text"},[_vm._v("2017 Portugal Laranjeiras Plaza project")]),_c('p',{staticClass:"text"},[_vm._v("2017 Malta branch established")]),_c('p',{staticClass:"text"},[_vm._v(" 2017 Portugal Condoninium Quinta da Pedra d'água project，the prime design villas ")]),_c('p',{staticClass:"text"},[_vm._v("2017 Bonni Property Management established")]),_c('p',{staticClass:"text"},[_vm._v(" 2018 Revealing 4 sub-branding collections of Greece developments ")]),_c('div',{directives:[{name:"lazy",rawName:"v-lazy:background-image",value:(
          '//static.hoolihome.com/global-jwp/assets/m/about/course-3.jpg'
        ),expression:"\n          '//static.hoolihome.com/global-jwp/assets/m/about/course-3.jpg'\n        ",arg:"background-image"}],staticClass:"milestone"},[_c('p',{staticClass:"milestone-title"},[_vm._v("MILESTONE 03")]),_c('p',{staticClass:"milestone-desc",staticStyle:{"max-width":"285px"}},[_vm._v(" Thailand Phuket Laya Resort Project embodies the JWP real estate into 4 development sectors accordingly ")])]),_c('div',{staticClass:"box"},[_vm._v(" 2017 Ireland JWP ICAV was officially approved ")]),_c('div',{staticClass:"box"},[_vm._v(" 2019 Portugal JWP FUND was officially approved ")]),_c('div',{staticClass:"box",staticStyle:{"margin-bottom":"10px"}},[_vm._v(" 2019 Cyprus JWP AIF V.C.I.C. PLC was officially approved ")]),_c('p',{staticClass:"text"},[_vm._v("2018 University of Nicosia Student Apartment project")]),_c('p',{staticClass:"text"},[_vm._v(" 2019 Strategic partnership with Japanese public company Tateru ")]),_c('p',{staticClass:"text"},[_vm._v(" 2019.8 Turkey Real Estate Investment Project Launched ")]),_c('p',{staticClass:"text"},[_vm._v("2019 Cyprus Sixty6 Tower project")])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"banner"},[_c('p',{staticClass:"title"},[_vm._v("LANDMARK DEVELOPMENTS")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"title"},[_c('span',{staticClass:"van-hairline--bottom"},[_vm._v("Landmark Developments")])])
}]

export { render, staticRenderFns }