<template>
  <section class="page-course">
    <div class="banner">
      <p class="title">LANDMARK DEVELOPMENTS</p>
    </div>
    <div class="course">
      <p class="title">
        <span class="van-hairline--bottom">Landmark Developments</span>
      </p>
      <div class="step blue">
        <p class="phase">PHASE ONE</p>
        <p class="duration">2013 - 2014</p>
        <p class="sub-title">
          From representative model to acquisition and development model
        </p>
        <p class="text">2013 JWP launched at Athens, Greece</p>
        <p class="text">2013.3 Portugal branch established in Lisbon</p>
        <p class="text">2013 Cyprus Atlantis Gardens Project</p>
        <div
          class="milestone"
          v-lazy:background-image="
            '//static.hoolihome.com/global-jwp/assets/m/about/course-1.jpg'
          "
        >
          <p class="milestone-title">MILESTONE 01</p>
          <p class="milestone-desc">
            2013 Cyprus Atlantis Gardens Project, the Beginning of Brand
            Recognition
          </p>
        </div>
        <p class="text">2014 Portugal Belas Golf Residence Project</p>
      </div>
      <div class="step red">
        <p class="phase">PHASE TWO</p>
        <p class="duration">2015 - 2016</p>
        <p class="sub-title">
          From signal development model to diversified development model
        </p>
        <p class="text">
          2015 Portugal 8 Building，the landmark residential project
        </p>
        <p class="text">2015 Radisson Hotel signed a contract successfully</p>
        <div
          class="milestone"
          v-lazy:background-image="
            '//static.hoolihome.com/global-jwp/assets/m/about/course-2.jpg'
          "
        >
          <p class="milestone-title">MILESTONE 02</p>
          <p class="milestone-desc">
            2015 Radisson Blu signed successful, forming the multi-industry
            landscape of residential and hospitality
          </p>
        </div>
        <p class="text">2015.8 Thailand branch established in Bangkok</p>
        <p class="text">2015.12 Ireland branch established</p>
        <p class="text">2016 Portugal Mastro Apartment project</p>
        <p class="text">2016 Ireland Kilcoole Nursing Home project</p>
      </div>
      <div class="step blue">
        <p class="phase">PHASE THREE</p>
        <p class="duration">2017 to date</p>
        <p class="sub-title">
          Extending the 4 development sectors with large scale and high profile,
          empowered JWP business model from real estate developing, exclusive
          representative, to property services sufficiently
        </p>
        <p class="text">2017 Portugal Laranjeiras Plaza project</p>
        <p class="text">2017 Malta branch established</p>
        <p class="text">
          2017 Portugal Condoninium Quinta da Pedra d'água project，the prime
          design villas
        </p>
        <p class="text">2017 Bonni Property Management established</p>
        <p class="text">
          2018 Revealing 4 sub-branding collections of Greece developments
        </p>
        <div
          class="milestone"
          v-lazy:background-image="
            '//static.hoolihome.com/global-jwp/assets/m/about/course-3.jpg'
          "
        >
          <p class="milestone-title">MILESTONE 03</p>
          <p class="milestone-desc" style="max-width: 285px;">
            Thailand Phuket Laya Resort Project embodies the JWP real estate
            into 4 development sectors accordingly
          </p>
        </div>
        <div class="box">
          2017 Ireland JWP ICAV was officially approved
        </div>
        <div class="box">
          2019 Portugal JWP FUND was officially approved
        </div>
        <div class="box" style="margin-bottom: 10px;">
          2019 Cyprus JWP AIF V.C.I.C. PLC was officially approved
        </div>
        <p class="text">2018 University of Nicosia Student Apartment project</p>
        <p class="text">
          2019 Strategic partnership with Japanese public company Tateru
        </p>
        <p class="text">
          2019.8 Turkey Real Estate Investment Project Launched
        </p>
        <p class="text">2019 Cyprus Sixty6 Tower project</p>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  beforeCreate() {
    document.title = "Landmark Developments";
  }
};
</script>

<style lang="less" scoped>
.page-course {
  .banner {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 158px;
    background: url("//static.hoolihome.com/global-jwp/assets/m/about/course-banner.jpg")
      no-repeat center center / cover;
    .title {
      margin-top: 22px;
      font-size: 20px;
      font-weight: 500;
      color: rgba(255, 255, 255, 1);
      text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
    }
  }
  .course {
    padding: 20px 22px 31px;
    .title {
      padding-bottom: 26px;
      text-align: center;
      span {
        padding-bottom: 4px;
        font-size: 20px;
        font-weight: 300;
        color: rgba(1, 61, 125, 1);
        &::after {
          border-color: rgba(1, 61, 125, 1);
        }
      }
    }
    .step {
      padding-bottom: 31px;
      .phase {
        height: 33px;
        font-size: 24px;
        font-family: Arial-Black, Arial;
        font-weight: bolder;
        line-height: 33px;
      }
      .duration {
        height: 28px;
        margin-top: -5px;
        font-size: 20px;
        font-weight: bold;
        line-height: 28px;
      }
      .sub-title {
        font-size: 14px;
        font-weight: bold;
        line-height: 20px;
      }
      .text {
        color: rgba(38, 38, 38, 1);
        font-size: 12px;
        font-weight: 500;
        line-height: 20px;
      }
      .milestone {
        width: 331px;
        height: 124px;
        margin: 6px 0 10px;
        background-color: #ffffff;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        .milestone-title {
          font-size: 16px;
          font-weight: bold;
          color: rgba(255, 255, 255, 1);
          line-height: 22px;
          text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.59);
        }
        .milestone-desc {
          font-size: 12px;
          color: rgba(255, 255, 255, 1);
          line-height: 20px;
          text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.59);
        }
      }
      .box {
        line-height: 17px;
        margin-bottom: 12px;
        border-radius: 4px;
        border: 1px solid rgba(181, 140, 84, 1);
        padding: 17px 20px 17px 23px;
        font-size: 12px;
        font-weight: 400;
        color: rgba(181, 140, 84, 1);
      }
      &.blue {
        .phase {
          color: rgba(254, 102, 73, 1);
          background: linear-gradient(
            180deg,
            rgba(1, 61, 125, 1) 0%,
            rgba(255, 255, 255, 1) 100%
          );
          -webkit-background-clip: text;
          background-clip: text;
          -webkit-text-fill-color: transparent;
        }
        .duration {
          color: rgba(1, 61, 125, 1);
        }
        .sub-title {
          color: rgba(1, 61, 125, 1);
        }
        .milestone {
          padding: 36px 0 0 23px;
          .milestone-desc {
            max-width: 237px;
          }
        }
      }
      &.red {
        text-align: right;
        .phase {
          color: rgba(181, 140, 84, 1);
          background: linear-gradient(
            180deg,
            rgba(181, 140, 84, 1) 0%,
            rgba(255, 255, 255, 1) 100%
          );
          -webkit-background-clip: text;
          background-clip: text;
          -webkit-text-fill-color: transparent;
        }
        .duration {
          color: rgba(181, 140, 84, 1);
        }
        .sub-title {
          color: rgba(181, 140, 84, 1);
        }
        .milestone {
          padding: 35px 23px 0 0;
          text-align: right;
          .milestone-desc {
            display: inline-block;
            max-width: 231px;
            text-align: right;
          }
        }
      }
    }
  }
}
</style>
